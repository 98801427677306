<template>
  <blank-layout>
    <div class="w-full">
      <a-alert type="success">
        Stream Ended. Please refresh your page if you think you shouldn't have seen this message.
      </a-alert>
    </div>
  </blank-layout>
</template>

<script>
import BlankLayout from '@/layouts/blank.vue';
export default {
  components: {
    BlankLayout
  }
};
</script>
